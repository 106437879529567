
import { defineComponent } from 'vue';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import CInput from '@/components/shared/CInput.vue';
import CButton from '@/components/shared/CButton.vue';
import CTextarea from '@/components/shared/CTextarea.vue';
import { ContactData } from '@/models/ContactData.model';
import { submitContactForm } from '@/api/endpoints/contact/contact.endpoints';
import { capitalizeWord, t } from '@/utils/utils';
import { CONTACT_MAIL } from '@/utils/constants.utils';
import { useMeta } from 'vue-meta';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'Contact',
	components: { CTextarea, CButton, CInput },
	setup() {
		useMeta({
			title: t('nav.contact'),
		})
	},
	data() {
		return {
			faEnvelope,
			capitalizeWord,
			mail: CONTACT_MAIL,
			data: { email: '', name: '', title: '', message: '' } as ContactData,
			errors: { email: '', name: '', title: '', message: '' } as ContactData,
		};
	},
	methods: {
		async onSubmit(): Promise<void> {
			// Show errors for required inputs
			Object.keys(this.data).forEach((key: string) => {
				this.data[key] = this.data[key].trim();
				this.errors[key] = !this.data[key] ? this.$t('message.requiredFiled') : '';
			});

			const hasError = Object.values(this.errors).some(Boolean);
			if (hasError) {
				return;
			}

			try {
				await submitContactForm(this.data);

				alert(this.$t('message.successfulAction'));
			} catch (error) {
				console.error(error);
				const { errors } = error.response.data;

				// Show errors from BE
				Object.keys(errors as Record<string, string[]>).forEach((key) => {
					this.errors[key] = errors[key].join(' ');
				});

				alert(this.$t('message.unsuccessfulAction'));
			}
		},
	},
});
