
import { defineComponent, ref } from 'vue';
import { generateId } from '@/utils/utils';
import { useModelWrapper } from '@/utils/modelWrapper.utils';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'CTextarea',
	props: {
		modelValue: {
			required: true,
			type: String,
		},
		label: {
			required: false,
			type: String,
			default: () => '',
		},
		error: {
			required: false,
			type: String,
			default: undefined,
		},
		isFullWidth: {
			required: false,
			type: Boolean,
			default: true,
		},
	},
	emits: ['update:modelValue'],
	setup(props, { emit }) {
		const model = useModelWrapper(props, emit);
		const isFocused = ref<boolean>(false);

		return {
			model,
			isFocused,
		};
	},
	data() {
		return {
			id: generateId(),
		};
	},
	computed: {
		toRenderLabel(): boolean {
			return !!this.label || !!this.$slots?.label;
		},
	},
});
